/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$app-success:		#8ac402; // #78d408; // #5dd40f;
// $app-warning:	#ffcc00;
$app-white:			#ffffff;
$app-warning:		var(--ion-color-warning);
$app-warning-shade:	#edb607; // var(--ion-color-warning-shade);
$app-info:			#478aff; // #0fa3fc;
$app-info-light:	rgba(15, 163, 252, 0.5);
$app-danger:		#ea5d00;
$app-blue:			#4c8dff;
$app-orange:		#ffae00;
$app-purple:		rgb(187, 92, 255);
$app-dark:			#4d5c70;
$app-base:			#3c4858;
$app-grey:			#cccccc;
$app-mid-grey:		#aaaaaa;
$app-primary:		#3880ff;

.brand {
	width: 100%;
	display: flex;
	margin-top: 24px;
	justify-content: center;
	color: #444444;

	.brand-img {
		position: relative; 
		height: 71px;
		transition: all 0.25s linear !important; 
	}
	.brand-name {
		font-size: 32px;
		font-weight: 600;
		// color: #444444;
		position: relative;
		margin-top: 42px;
		margin-left: 12px;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		transition: all 0.25s linear !important;
		--ion-font-family: oswald, sans-serif !important;
		font-family: oswald, sans-serif !important;

	}
	.reg {
		position: relative;
		margin-top: 40px;
		margin-left: 2px;
		font-weight: 600;
		--ion-font-family: varelaRound, sans-serif !important;
		font-family: varelaRound, sans-serif !important;
	}
}

.static-brand-img {
	position: absolute; 
	top: 4%; 
	left: 50%; 
	height: 6%;
	transform: translateX(-50%); 
	transition: all 0.3s linear !important; 
	cursor: pointer;
	&.not-connected {
		opacity:0.3;
		filter: grayscale(100%);
	}
}
.update-indicator {
	position: absolute;
	top: 55px;
	left: 50%;
	transform: translateX(9px); 

	height:20px;
	color: $app-white;
	border-radius: 10px;
	background-color: $app-success;

	font-weight: 700;
	font-size: 0.65rem;
	padding: 0.137rem 10px 0 10px;
	letter-spacing: 0.05rem; cursor: pointer;
}
ion-header {
	&.page {
		// background-color: red !important;
		// --background-color: red !important;
		padding-top: 12px;
		padding-bottom: 12px;
			ion-title {
			font-size: 1.6rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.2rem;
			color: $app-info;
		}
		ion-buttons {
			ion-back-button {
				margin-left: 15px;		
				--ripple-color: transparent !important;
				--background-hover: transparent !important;
				--background-focused: transparent !important;
				--background-activated: transparent !important;
				--background-hover-opacity: 0  !important;
				--background-focused-opacity: 0  !important;
				--background-activated-opacity: 0 !important;
	
				&::part(icon) {
					font-size: 40px;
					color: $app-info !important;
					--ionicon-stroke-width: 44px !important;
				}			
			}
			ion-button {
				margin-top: 12px;
				margin-right: 15px;	
				font-size: 80px !important;	
				ion-icon {
					// &.deactivate {
						font-size: 80px !important;
						--font-size: 80px !important;
						color: $app-danger !important;	
						--ionicon-stroke-width: 44px !important;
					// }
				}
			}
		}	
	}

	&.modal {
		background: white; 
		padding-bottom: 18px;
		height: 74px !important; 
		border-bottom: 1px solid #dedede; 

		ion-toolbar {
			ion-title {
				padding-top: 18px; 
				padding-bottom: 18px;
				height: 74px !important; 
				// color: $app-white;
				&.active,
				&.success,
				&.processing {
					color: $app-success;
				}
				&.info {
					color: $app-info;
				}
				&.queued, 
				&.warning {
					color: $app-warning;
				}
				&.pending, 
				&.danger {
					color: $app-danger;
				}		
			}
			ion-buttons {
				margin-top: 2px;
			}
		}
	}
}
ion-content {
	padding: 0;

	&.login,
	&.select-location,
	&.sign-in-out {
		--background: #ffffff;
		h2 {
			margin-bottom: 25px;
		}
		form > .input-group,
		.settings-request,
		.input-detail,
		.primed-card,
		.select-mode,
		.entry-mode,
		.exit-mode {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: 0;
			width: 48%;
			padding: 0;
			height: auto;
			max-height: 900px;
			border-radius: 18px;
			background: $app-white;
			overflow: hidden !important;
			transform: translate(-50%, -50%);
			border: 10px solid #ededed; 
			transition: all 0.3s linear !important;

			&.no-border {
				border: none; 
			}
			&.default {
				background: #ededed;
			}
			.mode-btns {
				position: relative !important;
				&.center {
					text-align: center;
				}
				// ion-button {
				// 	// position: absolute !important;
				// 	&.middle {
				// 		// align-self: start;
				// 		// left: calc(50% - 80px) !important; 
				// 		margin-left: 60px;
				// 		margin-right: 60px;
				// 	}
				// 	// &.left {
				// 	// 	left: 36px !important;
				// 	// }
				// 	// &.right {
				// 	// 	right: 36px !important;
				// 	// }
				// }
			}
			&.duple {
				max-height: 500px;
				transition: max-height 0.3s ease-in;
			}
			&.info {
				background: $app-info;
				border: 10px solid $app-info !important;
				.ion-icon-avatar {
					color: $app-info;
				}
			}
			&.success {
				background: $app-success;
				border: 10px solid $app-success !important;
				.ion-icon-avatar {
					color: $app-success;
				}
			}
			&.warning {
				background: $app-warning;
				border: 10px solid $app-warning !important;
				.ion-icon-avatar {
					color: $app-warning;
				}
			}
			&.danger {
				background: $app-danger;
				border: 10px solid $app-danger !important;
				.ion-icon-avatar {
					color: $app-danger;
				}
			}

			.primed-card-header {
				@extend .app-header;
				// color: #444444;
				// &.themed {
				// 	color: $app-white;
				// }
				&.text-dark {
					color: #444444; 
				}			
				&.warning {
					color: $app-warning-shade; 
				}			
				&.success {
					color: $app-success; 
				}			
				&.danger {
					color: $app-danger; 
				}			
			}
			.guest-profile {
				display: grid;
				grid-template-columns: 120px auto !important;
				grid-gap: 16px; 
				background: #ffffff;
				padding: 16px; 
				border-radius: 9px 9px 0 0;
				.ion-icon-avatar {
					margin: 0px !important;
					margin-bottom: -4px !important;
				}
				.badge {
					position: absolute; 
					margin-top: 74px; 
					margin-left: 76px; 
					border: 6px solid $app-info; 
					background-color: white; 
					border-radius: 50%; 
					padding: 6px;
					.ion-icon-avatar {
						color: $app-info;
					}
				}
				.guest-profile-details {
					display: flex; 
					flex-direction: column; 
					justify-content: center;
					overflow: hidden;
					.profile-name {
						font-size: 1.6rem; 
						font-weight: 600; 
						width: calc(100%);
						overflow: hidden; 
						white-space: nowrap; 
						text-overflow: ellipsis;
					}
					.profile-tag,
					.profile-email,
					.profile-phone {
						display: flex; 
						align-items: flex-start; 
						font-size: 1.2rem; 
						font-weight: 600; 
						ion-icon {
							@extend .font-24;
							@extend .thick;
							margin-top: 2px; 
							margin-right: 9px;
						}
						span {
							display: inline-block; 
							overflow: hidden; 
							white-space: nowrap; 
							text-overflow: ellipsis;
							border-radius: 4px;
							max-width: calc(100% - 30px);
							&.blurred {
								// text-shadow: 0 0 28px black;
								background: #eeeeee;
								color: transparent;
							}
						}
					}
					.highlight {
						margin-top: 4px;
						margin-left: -1px;
						ion-icon {
							@extend .font-30;
							// @extend .extra-thick;
							margin-top: 0px;
							margin-right: 5px;
							color: $app-warning-shade;
						}
						span {
							color: $app-white;
							border-radius: 6px;
							letter-spacing: 0.03rem;
							background: $app-warning-shade;
							padding: 2px 9px 3px 9px !important;
						}
						&.queued {
							ion-icon {
								color: $app-warning-shade;
							}
							span {
								background: $app-warning-shade;
							}
						}
						&.processing {
							ion-icon {
								color: $app-success;
							}
							span {
								background: $app-success;
							}
						}
						&.pending {
							ion-icon {
								color: $app-danger;
							}
							span {
								background: $app-danger;
							}
						}
					}

				}
			}
			.date-time {
				// margin-top: 10px !important; 
				overflow: hidden; 
				background: #ffffff; 
				border-top: 1px solid #dcdcdc;
				// border-radius: 9px !important;
				ion-icon {
					@extend .font-40;
					@extend .thick;
					&[slot~="start"] {
						margin-left: 10px; 
						margin-right: 26px; 
						padding-top: 18px; 
						padding-bottom: 18px;
					}
					&.time-icon {
						position: absolute; 
						left: 64%;
					}
				}
				ion-label {
					&.time-label {
						position: absolute; left: calc(64% + 48px);
					}
					h3 {
						font-size: 1.4rem; 
						// font-weight: 600;
					}
				} 
			}
			.host-profile {
				// margin-top: 10px !important; 
				overflow: hidden; 
				border-top: 1px solid #dcdcdc;
				border-radius: 0 0 9px 9px;
				background: #ffffff; 
				ion-icon {
					@extend .font-40;
					@extend .thick;
					// @extend .info;
					&[slot~="start"] {
						margin-left: 10px; 
						margin-right: 26px; 
						padding-top: 18px; 
						padding-bottom: 18px;	
					}
					&[slot~="end"] {
						@extend .font-32;
						@extend .info;
					}
				}
				ion-label h3 {
					font-weight: 600;
				}
			}
			.feedback {
				overflow: hidden; 
				border-top: 1px solid #dcdcdc;
				// border-radius: 0 0 9px 9px;
				background: #ffffff; 
				text-align: center;
				display: flex;
				.rating {
					display: inline-block;
					border: 1px solid #4c8dff;
					height: 70px;
					flex-grow: 1;
					align-items: center;
					justify-content: center;
					transition: all 0.25s ease-in-out;

					background-color: #cdcdcd;
					mask-position: center;
					mask-repeat: no-repeat;
					mask-size: 80% 80%;
			
					// &.booking {
						-webkit-mask-image: url(/assets/icon/star-outline.svg);
						mask-image: url(/assets/icon/star-outline.svg);	
					// }
					&.rated {
						background-color: $app-info !important;
						-webkit-mask-image: url(/assets/icon/star.svg) !important;
						mask-image: url(/assets/icon/star.svg) !important;	
						&.one {
							background-color: $app-danger !important;						
						}
						&.two {
							background-color: #F59105 !important;						
						}
						&.three {
							background-color: $app-warning!important;						
						}
						&.four {
							background-color: #C5C406 !important;						
						}
						&.five {
							background-color: $app-success !important;						
						}
					}		
				}
			}

			ion-item {
				margin: 0;
				--background-focused: #ffffff;
				ion-input {
					height: 70px;
					font-size: 1.5rem;
					&.phone-input {
						margin-top: -4px !important; 
						margin-bottom: -4px !important; 
						padding-top: 0px !important; 
						padding-bottom: 0px !important; 
						padding-left: 8px !important; 
						border-left: 1px solid #cccccc;
					}
					&.invalid {
						color: $app-danger;
					}				
				}
			}
		}
	}
	.screen-label {
		position: absolute;
		top: 22%;
		font-size: 26px;
		font-weight: 700;
		letter-spacing: 0.18rem;
		color: #999999;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		width: 100%;
		transition: all 0.25s linear !important;

		&.mode-on {
			font-size: 48px;
			color: #bbbbbb;
			letter-spacing: 0.04rem;
			text-transform: lowercase;
		}
		&.nudge-up {
			top: 14%;
			font-size: 42px;
			color: #cccccc;
			letter-spacing: 0.036rem;
			text-transform: lowercase;
		}
		&.nudge-mid {
			@extend .nudge-up;
			top: 17%;
			// font-size: 42px;
			// color: #cccccc;
			// letter-spacing: 0.036rem;
			// text-transform: lowercase;
		}
		&.danger {
			opacity: 0.8;
			color: $app-danger
		}
		&.success {
			opacity: 0.7;
			color: $app-success;
		}
		&.fade-out {
			opacity: 0;
		}
	}
	.scan-qr-btn {
		height:102px; 
		width: 102px; 
		padding: 15px; 
		border: none !important; 
		border-radius: 24px !important; 
		background: #f2f2f2;
		position: absolute;
		bottom: 18%;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.25s linear !important;
	}
}
.popover-content {
	@extend .app-menu;
}
.app-header {
	display: flex !important; 
	align-items: center !important; 
	justify-content: center !important; 
	height: 48px; 
	font-weight: 600; 
	font-size: 1.25rem; 
	padding-bottom: 8px; 
	letter-spacing: 0.125rem; 
	color: white; 
	text-transform: uppercase;

	&.text-dark {
		color: #444444; 
	}

	ion-icon {
		cursor: pointer;
		position: absolute;
		right: 6px !important;
	}
}
.app-menu {
	opacity: 1 !important;

	&.leak {
		position: absolute;
		left: 0 !important;
	}
	.popover-arrow {
		&.sc-ion-popover-ios {
			background: blue;
			display: none;
		}
		background: red;
	}
	&::part(backdrop) {
		background: rgba(255, 255, 255, 0) !important;
		// --backdrop-opacity: 0.97;
	}				
	&::part(content) {
		width: 200px !important;
		border-radius: 9px !important;
	}				
	
	// .popover-content {
	// 	overflow: hidden;
	// 	border-radius: 9px !important;
	// 	background: $app-blue !important;
	// }
	ion-list {
		background: $app-blue !important;
		// --background: transparent !important;
		margin: 0 !important;
		padding: 0 !important;
		ion-item {
			color: $app-white !important;
			--background: $app-blue;
			--border-color: rgba(255, 255, 255, 0.1);
			ion-icon {
				color: $app-white;
			}				
			ion-label {
				color: $app-white !important;
			}
			* {
				color: $app-white !important;
			}
		}
	}
	.alert-radio-label {
		color: $app-white !important;
		--color: #ff0000 !important;
		--color-hover: #362419 !important;
	}
	.app-select-option {
		color: $app-white !important;
		--color: #ff0000 !important;
		--color-hover: #362419 !important;

	}
}
.scan-box {
	position: absolute;
	top: 50%;
	left: 50%;
	content: '';
	width: 48%;
	height: 440px;
	display: block;
	border-radius: 36px;
	border: 20px solid #ededed;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 0 100vmax rgb(255, 255, 255, 1.0);
}

ion-button.access-mode {
	border: none !important;
	color: $app-white !important;
	border-radius: 40px !important;
	--border-radius: 40px !important;
	--padding-start: 36px;
	--padding-end: 36px;
	height: 72px !important;
	font-size: 1.5rem !important;
	font-weight: 600 !important;
	min-width: 160px !important;
	letter-spacing: 0.003rem;
	box-shadow: 0px 0px 24px -12px rgba(0, 0, 0, 0.27);
	--box-shadow: 0px 0px 24px -12px rgba(0, 0, 0, 0.27);
	transition: all 0.25s linear !important;

	&.decline {
		--background: #ea5d00;
		--background-hover: #ea5d00;
		--background-activated: #cc5100;
	}
	&.update {
		--background: #edb607;
		--background-hover: #edb607;
		--background-activated: #cfa600;
	}
	&.save,
	&.approve {
		--background: #8ac402;
		--background-hover: #8ac402;
		--background-activated: #8ac402;
	}
}
ion-icon {
	&[slot~="start"] {
		margin: auto;
		margin-left: 4px;
		margin-right: 20px;
	}
	&.success {
		color: $app-success;
	}
	&.warning {
		color: $app-warning;
	}
	&.danger {
		color: $app-danger;
	}
	&.info {
		color: $app-info;
	}
	&.grey {
		color: $app-grey;
	}
	&.font-24 {
		font-size: 24px !important;
	}
	&.font-28 {
		font-size: 24px !important;
	}
	&.font-30 {
		font-size: 30px !important;
	}
	&.font-32 {
		font-size: 32px !important;
	}
	&.font-36 {
		font-size: 36px !important;
	}
	&.font-40 {
		font-size: 40px !important;
	}
	&.font-48 {
		font-size: 48px !important;
	}
	&.font-56 {
		font-size: 56px !important;
	}
	&.font-60 {
		font-size: 60px !important;
	}
	&.font-64 {
		font-size: 64px !important;
	}
	&.font-72 {
		font-size: 72px !important;
	}
	&.font-80 {
		font-size: 80px !important;
	}
	&.font-100 {
		font-size: 100px !important;
	}
	&.font-120 {
		font-size: 120px !important;
	}
	&.food {
		font-size: 36px;
		color: $app-orange !important;
	}
	&.doc {
		font-size: 36px;
		color: $app-info !important;
	}
	&.package {
		font-size: 36px;
		color: $app-purple !important;
	}
	&.down {
		margin-top: 14px !important;
	}
	&.semi-thick {
		--ionicon-stroke-width: 36px !important;
	}
	&.thick {
		--ionicon-stroke-width: 44px !important;
	}
	&.med-thick {
		--ionicon-stroke-width: 52px !important;
	}
	&.extra-thick {
		--ionicon-stroke-width: 60px !important;
	}
	&.transitions {
		transition: all 0.25s linear !important; 
	}
	&.hidden {
		opacity: 0;
	}
	&.context-icon {					
		position: relative; 
		left: 0px; 
		right: 4px;
		font-size: 22px;
		top: 0px !important;
		color: #777777;
		// bottom: -6px !important;
	}
}
ion-button.country-code {
	--background: #ffffff;
	--background-hover: #ffffff;
	--background-activated: #ffffff;
	--border-radius: 0px !important;
	--padding-start: 0px !important;
	// --padding-end: 0px !important;
	--padding-top: 0.67rem !important;
	--padding-bottom: 8px !important;
	--color-activated: #478aff !important;
	--ripple-color: #ffffff;
	margin: 0 !important;
	font-size: 1.4rem;
	width: 92px !important; 
	max-width: 92px !important; 
	height: 70px; 
	color: $app-mid-grey; 
	box-shadow: none !important;
	--box-shadow: none !important;

	&.info {
		color: $app-info; 
		ion-icon {
			color: $app-info; 
		}
	}
}

ion-backdrop {
	background: rgba(255, 255, 255, 1) !important;
	--backdrop-opacity: 0.8;
}
ion-item {
	ion-label {
		&.info {
			color: $app-info !important;
			&:hover {
				cursor: pointer;
			}
		}
		h3 {
			font-size: 1.4rem !important;
			color: inherit !important;
			&.user-name {
				font-weight: 500;
				letter-spacing: 0.04rem;
			}
			&.queue-code {
				// font-size: 1.5rem !important;
				font-weight: 700;
				letter-spacing: 0.04rem;
			}
		}
		p {
			font-size: 1.05rem !important;
			color: inherit !important;
		}	
	}
	&.no-padding {
		--inner-padding-bottom: 0px !important;
		--inner-padding-end: 0px !important;
		--inner-padding-start: 0px !important;
		--inner-padding-top: 0px !important;
	}
	&.pv-6	{
		--inner-padding-bottom: 6px !important;
		--inner-padding-top: 6px !important;
	}
	&.pv-7-8	{
		--inner-padding-bottom: 7px !important;
		--inner-padding-top: 8px !important;
	}
	&.pv-12	{
		--inner-padding-bottom: 12px !important;
		--inner-padding-top: 12px !important;
	}
	&.mt-3 {
		margin-top: 3px ;
	}
	&.approved,
	&.processing {
		// border-left: 6px solid $app-success;
		ion-icon {
			color: $app-success;
		}
		.status {
			background: $app-success;
		}
	}

	&.queued {
		// border-left: 6px solid $app-warning;
		ion-icon {
			color: $app-warning;
		}
		.status {
			background: $app-warning;
		}
	}
	&.pending,
	&.declined {
		// border-left: 6px solid $app-danger;
		ion-icon {
			color: $app-danger;
		}
		.status {
			background: $app-danger;
		}
	}
	ion-note {
		&.status {
			padding: 5px 8px 4px 8px;
			color: $app-white;
			font-size: 0.82rem;
			text-transform: uppercase;
			letter-spacing: 0.06rem;
			font-weight: 600;
			border-radius: 6px;
			margin-top: 22px;
		}
	}
}
ion-alert {
	--background: transparent !important;
	.alert-wrapper {
		--background: transparent !important;
		--min-width: 100% !important;
		position: absolute !important;
		top: calc(25% - 20px);
		height: calc(75% + 20px) !important;
	}
	ion-backdrop {
		background: rgba(255, 255, 255, 1) !important;
		--backdrop-opacity: 1.0;
	}
	
	h2 {
		font-size: 2rem !important;
		color: $app-info !important;
		margin-top: -10px !important;
		margin-bottom: 7% !important;
		text-align: center !important;
	}

	div {
		font-size: 1.5rem !important;
		font-weight: 600 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;

		&:nth-child(2) {
			background-color: #f4f4f4 !important;
			max-width: 80%;
			line-height: 40px;
			padding: 22px 20px 16px 20px !important;
			border-radius: 12px;
			margin-left: auto;
			margin-right: auto;
			text-align: left !important;

			ion-icon {
				margin-right: 15px;
				font-size: 32px;
				margin-bottom: -8px;
			}
			span {
				width: 100%;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&.highlight {
					font-size: 125%;
					margin-top: 20px;
					margin-bottom: 20px;
					color: $app-info;
				}
				&.w-66 {
					width: 66%;
				}
				&.w-34 {
					width: 34%;
				}
				&.success {
					ion-icon {
						color: $app-success;
					}
				}
				&.danger {
					ion-icon {
						color: $app-danger;
					}
				}
			}
		}
		&:last-child {
			background-color: $app-orange !important;
			width: 100% !important;
			position: absolute !important;
			bottom: 98px;
		}
	}
	button {
		border: none !important;
		height: 72px !important;
		min-width: 160px !important;
		font-size: 1.5rem !important;
		font-weight: 600 !important;
		color: $app-white !important;
		position: absolute !important;
		border-radius: 40px !important;
		// --box-shadow: none;
		--padding-start: 30px !important;
		--padding-end: 30px !important;
		padding-left: 30px !important;
		padding-right: 30px !important;
		&.middle {
			left: calc(50% - 80px); 
			background: $app-blue !important;		
		}
		&.left {
			left: 36px;
			background: #888888 !important;
		}
		&.right {
			right: 36px;
			background: $app-primary !important;
			&.delete {
				background: $app-danger !important;
			}
		}
	}
	&.exit-app,
	&.login-error {
			h2 {
			color: $app-danger !important;
		}		
	}

}
ion-list {
	.app-list-header {
		// margin-top: -2px;
		padding: 15px 20px;
		font-weight: 600;
		font-size: 1.3rem;
		letter-spacing: 0.12rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-transform: uppercase;
		background-color: #eeeeee;
		color: #666666;
		&.border-btm {
			border-bottom: 1px solid #dedede;
		}
		&.border {
			border-top: 1px solid #dedede;
			border-bottom: 1px solid #dedede;
		}
		&.mrg-btm {
			margin-bottom: 2px;
		}
	}
	ion-item {
		ion-label {
			font-size: 1.4rem !important;
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			&.selected {
				font-weight: 600 !important;
				color: $app-info !important;
			}
		}
		ion-checkbox {
			--size: 24px;
			--border-width: 3px;
			--border-color: #777777;
			--background-checked: #478aff;
			--border-color-checked: #478aff;
			&::part(container) {
				border-radius: 6px;
				// border: 3px solid #666666;
			}
		}
		
		&:hover {
			cursor: pointer;
		}
		&.country-code {
			ion-label {
				font-size: 1.4rem !important;
				padding-top: 10px !important;
				padding-bottom: 10px !important;
					span.code {
					width: 64px;
					display: inline-block;
				}
			}
		}	
		&.sign-out {
			// --background: #ea5d00;
			ion-icon {
				color: $app-danger !important;
			}
			ion-label {
				color: $app-danger !important;
				font-size: 1.4rem !important; 
				font-weight: 600;
			}
		}	
	}
	&.settings-list {
		margin-bottom: 0;
		padding-bottom: 0;
		&.pdd-top-0 {
			padding-top: 0px;
		}
		&.pdd-top-24 {
			padding-top: 24px;
		}
		ion-item {
			&.highlighted {
				&.success {
					background: $app-success;
					--background: $app-success;
				}
				&.info {
					background: $app-info;
					--background: $app-info;
				}
				ion-icon,
				ion-label {
					color: $app-white;
				}
			}
			ion-label {
				font-size: 1.4rem !important;
				padding-top: 15px !important;
				padding-bottom: 15px !important;
				&.enabled {
					color: $app-info !important;
				}
			}
		}
	}
}

.list-count-btn {
	height: 60px !important;
	border-radius: 36px !important;
	--border-radius: 36px !important;
	--padding-start: 24px;
	--padding-end: 24px;
	font-size: 1.8rem;
	font-weight: 600;
	margin-right: 25px;
	&.warning {
		--background: #edb607;
		--background-hover: #edb607;
		--background-activated: #cfa600;
	}
	&.success {
		--background: #8ac402;
		--background-hover: #8ac402;
		--background-activated: #8ac402;
	}
	&.danger {
		--background: #ea5d00;
		--background-hover: #ea5d00;
		--background-activated: #cc5100;
	}
	&.grey {
		--background: #cccccc;
		--background-hover: #cccccc;
		--background-activated: #cccccc;
	}
	&.greyer {
		--background: #aaaaaa;
		--background-hover: #aaaaaa;
		--background-activated: #aaaaaa;
	}
	&.round {
		--padding-start: 14px;
		--padding-end: 14px;
	}
}
.screen-modal {
	// .modal-wrapper {
	// 	width: calc(48% + 20px) !important;
	// }
	ion-title {
		font-size: 1.3rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.12rem;
	}
	&::part(backdrop) {
		background: rgba(255, 255, 255, 0.9) !important;
		// --backdrop-opacity: 0.97;
	}
	&::part(content) {
		padding: 0 0 0 0;
		width: 48% !important;
		border-radius: 18px !important;
		background: #ededed; 
		border: 10px solid #ededed; 
		box-shadow: none;
		--box-shadow: none;
		transition: all 0.28s linear !important;
		// box-shadow: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
		// --box-shadow: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
	}
	&.info,
	&.deffo {
		&::part(content) {
			background: $app-info; 
			border: 10px solid $app-info; 
		}
	}							
	&.queued,
	&.warning {
		&::part(content) {
			background: $app-warning; 
			border: 10px solid $app-warning; 
		}
	}							
	&.pending, 
	&.danger {
		&::part(content) {
			background: $app-danger;
			border: 10px solid $app-danger; 
		}
	}
	&.active,
	&.success,
	&.processing {
		&::part(content) {
			background: $app-success;
			border: 10px solid $app-success; 
		}
	}
	.btn-footer {
		display: flex; 
		align-items: center; 
		justify-content: center; 
		padding-top: 10px;
		ion-button {
			width: 100%; 
			height: 60px;
			font-size: 1.3rem;
			letter-spacing: 0.1rem;
			font-weight: 600 !important;
			color: $app-white !important; 
			border-radius: 9px !important;
			--border-radius: 9px !important;
			box-shadow: none !important; 
			--box-shadow: none !important; 
			&.info {
				--background: #478aff;
				--background-hover: #478aff;
			}
		}
	}
}
.search-list {
	margin-top:-1px; 
	position: relative; 
	box-shadow: none !important;
	// background-color: red;
	border-bottom:  1px solid #eaeaea !important;
	--border-bottom: 1px solid #eaeaea !important;
}
.search-bar {
	padding: 0;
	--box-shadow: none;
	width: 100% !important; 
	background-color: rgba(255, 255, 255, 0.5); 
	.searchbar-search-icon {
		height: 44px !important;
	}
	input.searchbar-input {
		font-size: 1.4rem;
		height: 64px !important;
		line-height: 64px !important;	
	}
}
ion-img.flag-thumb {
	width: 40px;
	overflow: hidden;
	border-radius: 6px;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}
.app-loading {
	// background: rgba(255, 255, 255, 0.8) !important;
	width: 100% !important;
    .loading-wrapper {
		width: 100% !important;
		--width: 100% !important;

		font-size: 1.8rem;
		color: #777777;
		font-weight: 400 !important;
		background: transparent;
		margin: 0px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-bottom: 100px !important;
        // box-shadow: none !important;
		// -webkit-box-sadow: none !important;
		// background: rgba(50, 50, 255, 0.8) !important;
		&::after {
			position: absolute;
			bottom: -180px;
			content: url('assets/images/others/loading-light-lg.gif');
		}
	}
	ion-backdrop {
		// background: rgba(255, 255, 255, 1) !important;
		background-image: url('assets/images/logo/eonii-logo-animated.svg') !important;
		background-size: 120px !important;
		background-repeat: no-repeat !important;
		background-position-x: center !important;
		background-position-y: 18% !important;
		--backdrop-opacity: 1 !important;
		// &::after {
		// 	position: absolute;
		// 	background-image: url('assets/images/logo/eonii-logo-animated.svg') !important;
		// 	background-size: 10px 20px;
		// 	width: 40px; 
		// 	height: 80px;
		// 	content: '' !important;
		// }
		// --backdrop-opacity: 0.85;
	}
	&.spin {
		.loading-wrapper {
			&::after {
				@extend .fa-spin;
				bottom: -120px;
				content: url('assets/images/others/syncing.gif');
			}
		}
	}
}

.fab-main-btn {
	--background: transparent;
	// --ionicon-stroke-width: 52px !important;
	--ripple-color: transparent !important;
	--background-activated: transparent !important;
	--background-activated-opacity: 0 !important;
	// --box-shadow: 0 6px 18px rgba(0, 0, 0, 0.18);
	color: #bbbbbb !important;
	--color: #bbbbbb !important;
	--color-activated: #bbbbbb !important;
	margin-top: 15px;
	margin-right: 15px;
	--box-shadow: none;

	&.right-2 {
		margin-right: 90px;
	}
	&.right-3 {
		margin-right: 170px;
	}
	&.success {
		--background: #8ac402;
		--ripple-color: #8ac402 !important;
		--background-activated: #8ac402 !important;
		--background-activated-opacity: 0 !important;
		color: #ffffff !important;
		--color: #ffffff !important;
		--color-activated: #ffffff !important;
	}
	&.info {
		--background: #478aff;
		--ripple-color: #478aff !important;
		--background-activated: #478aff !important;
		--background-activated-opacity: 0 !important;
		color: #ffffff !important;
		--color: #ffffff !important;
		--color-activated: #ffffff !important;
	}
	&.danger {
		--background: #ea5d00;
		--ripple-color: #ea5d00 !important;
		--background-activated: #ea5d00 !important;
		--background-activated-opacity: 0 !important;
		color: #ffffff !important;
		--color: #ffffff !important;
		--color-activated: #ffffff !important;
	}
}
.notif-box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 48%;
	overflow: hidden;
	min-height: 240px;
	color: $app-white;
	border-radius: 18px;
	background: #ededed;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border: 10px solid #cccccc;
	transform: translate(-50%, -50%);

	&.info {
		background: $app-info;
		border-color: $app-info;
	}
	&.warning {
		background: $app-warning;
		border-color: $app-warning;
	}
	&.success {
		background: $app-success;
		border-color: $app-success;
	}
	&.danger {
		background: $app-danger;
		border-color: $app-danger;
	}
	ion-icon {
		margin-top: 18px
	}
	h2 {
		display: block;
		font-size: 2rem;
		font-weight: 600;
		margin-top: 12px;
	}
	p {
		width: 100%;
		padding: 36px;
		display: block;
		color: #555555;
		font-weight: 600;
		font-size: 1.6rem;
		line-height: 2.2rem;
		text-align: center;
		border-radius: 9px;
		margin: 10px 0px 0px 0px;
		background: $app-white;
	}
}
.process-box {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 15%;
	overflow: hidden;
}
.app-avatar {
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #efefef;
	// border: 1px solid #bbbbbb;
	.initials {
		opacity: 0.65;
		color: black;
		margin-top: 0px;
		font-weight: 600;
		font-size: 1.25rem;
		letter-spacing: 0.04rem;
		&.text-white {
			opacity: 1;
			color: white;
		}
		&.info {
			opacity: 1;
			color: $app-info;
		}
	}
	&.large {
		width: 120px;
		height: 120px;
		.initials {
			margin-top: 4px;
			font-size: 2.5rem;
			letter-spacing: 0.08rem;
		}
	}
	&.selectable {
		background-color: rgba(15, 163, 252, 0.12);
		.initials {
			color: $app-info;
		}
	}
	&.info,
	&.invited {
		background-color: $app-info;
		&.border {
			border: 3px solid $app-info;
		}
		&.text-white {
			color: white;
		}
	}
	&.success,
	&.active {
		background-color: $app-success;
		&.border {
			border: 3px solid $app-success;
		}
	}
	&.warning,
	&.pending {
		background-color: $app-warning-shade;
		&.border {
			border: 3px solid $app-warning-shade;
		}
	}
	&.danger,
	&.expired {
		background-color: $app-danger;
		&.border {
			border: 3px solid $app-danger;
		}
	}
	&.priority {
		background-color: white;
		border: 6px solid $app-info;
	}
	&.icon {
		background-color: white;
		border: 1px solid transparent;
		ion-icon {
			margin: auto;
		}
	}
}
.screen-actions {
	background: $app-orange !important;
	background-color: $app-orange !important;
	width: 100% !important;
	position: absolute !important;
	bottom: 110px;
	// height: 3px;
	&.index-60 {
		z-index: 60;
	}
	ion-button {
		border: none !important;
		color: $app-white !important;
		border-radius: 40px !important;
		--border-radius: 40px !important;
		--padding-start: 36px;
		--padding-end: 36px;
		height: 72px !important;
		position: absolute !important;
		font-size: 1.5rem !important;
		font-weight: 600 !important;
		min-width: 160px !important;
		letter-spacing: 0.003rem;
		box-shadow: 0px 0px 24px -12px rgba(0, 0, 0, 0.27);
		--box-shadow: 0px 0px 24px -12px rgba(0, 0, 0, 0.27);
		transition: all 0.25s linear !important;

		&.middle {
			left: calc(50%) !important; 
			transform: translateX(-50%) !important;
		}
		&.left {
			left: 36px !important;
		}
		&.right {
			right: 36px !important;
		}
		&.default {
			--background: #478aff;
			--background-hover: #478aff;
		}
		&.cancel {
			--background: #888888;
			--background-hover: #888888;
			--background-activated: #767676;
			transition: all 0.25s linear;
		}
		&.decline {
			--background: #ea5d00;
			--background-hover: #ea5d00;
			--background-activated: #cc5100;
		}
		&.update {
			--background: #edb607;
			--background-hover: #edb607;
			--background-activated: #cfa600;
		}
		&.save,
		&.approve {
			--background: #8ac402;
			--background-hover: #8ac402;
			--background-activated: #8ac402;
		}
	}
}
.fa-spin {
	-webkit-animation: fa-spin 2.5s infinite linear;
	animation: fa-spin 2.5s infinite linear;
}
.fa-pulse {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

